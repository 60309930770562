import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueRouter from 'vue-router'
import './plugins/bootstrap-vue'
import App from './App.vue'
import Starter from './components/starter'
import Jul2021 from './components/synnmel'
import ThirtyYears from './components/30years'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Starter
  }, {
    path: '/jul2021',
    component: Jul2021
  }, {
    path: '/30years',
    component: ThirtyYears
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
