<template>
  <div>
    <b-container fluid class="background-cover godjul">
      <b-row class="vh-100 text-center" align-v="center">
        <b-col class="mt-5 mb=0">
          <h1>
            God jul verdens beste partner <br> og verdens beste mor!
          </h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="background-cover first">
      <b-row class="vh-100 text-center" align-v="center">
        <b-col>
          <div>
            <p>
              Eg setter veldig stor pris på deg, og du er verdens beste partner og mor.<br><br>
              Etter å blitt foreldre har forholdet endra seg noke, men eg elsker deg like masse, om ikkje meir! <br><br>
              Det å være småbarnsforeldre kan være krevande, og man får ikkje alltid tatt vare på seg sjølv, og det ønsker eg å legge til rette for i 2022!
            </p>
          </div>
        </b-col>
        <b-col>
          <div>
            <img src="../assets/images/synn_vogn_iver_vinter_loren.png" class="img-fluid" alt="">
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="background-cover second">
      <b-row class="vh-100 text-center" align-v="center">
        <b-col>
          <div>
            <img src="../assets/images/synn_lunch_lokka_02_360x480.png" class="img-fluid" alt="">
          </div>
        </b-col>
        <b-col>
          <div>
            <p>
              Sjølvpleie er viktig. Det viktig på vegne av seg sjølv, sin egen helse, men også på vegne av dei rundt seg. <br><br>
              Sjølvpleie gjør at man sjølv er sunn, man sjølv har det bra, man kan gjøre jobben sin, og at man kan hjelpe og ta være på andre. <br><br>
              Forskning viser at Sjølvpleie som trening, mindfullness, god ernæring, tilstrekkelig med søvn, være i nuet og ta pause fra "kvardagen" øker velvære og øker levetiden. <br><br>
              Eg tror og at sjølvpleie får ein til å gløde!
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="background-cover fourth">
      <b-row class="vh-100 text-center" align-v="center">
        <b-col>
          <div>
            <p>
              Det blir lagt til rette for en liten transformasjon! <br><br>
              Og litt pleie. <br><br>
              Det kan komme godt med med to små gutter som er født litt for tett?
            </p>
          </div>
        </b-col>
        <b-col>
          <div>
            <img src="../assets/images/synn_montage_before_after_480x480.png" class="img-fluid" alt="">
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="background-cover fifth">
      <b-row class="vh-100 text-center" align-v="center">
        <b-col>
          <div>
            <img src="../assets/images/personal-shopper-marianne-sandvika-storsenter_852x480.png" class="img-fluid" alt="">
          </div>
        </b-col>
        <b-col>
          <div>
            <p>
              Møt Marianne. Ho kan shoppe. Og ho vil bistå deg med å shoppe. <br><br>
              Du får 3 timer med Marianne på Sandvika Storsenter. Dei 3 timene inkluderer kartlegging av kva du ønsker og at Marianne velger ut noken plag og antrekke til deg i butikkene på senteret. <br><br>
              Dine egen personal shopper!
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="background-cover sixth">
      <b-row class="vh-100 text-center" align-v="center">
        <b-col>
          <div>
            <p>
              Du får også et gavekort på Sandvika Storsenter med 1.000,- NOK som kick-starter shoppingen. <br><br>
              Samtidig er gaven fra Mamma Sølvi og Pappa Erling tiltenkt å brukt samtidig, slik at du kan få ein heil dag fri fra barn og stress. Vi sørger for barnepass når det passer din kalender! <br><br>
            </p>
          </div>
        </b-col>
        <b-col>
          <div>
            <img src="../assets/images/synn_middag_akerb_oslo_360x480.png" class="img-fluid" alt="">
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="background-cover seventh">
      <b-row class="vh-100 text-center" align-v="center">
        <b-col>
          <div>
            <img src="../assets/images/synn_lunch_taverna_360x480.png" class="img-fluid" alt="">
          </div>
        </b-col>
        <b-col>
          <div>
            <p>
              Håper gaven(e) falt i smak. Du fortjener det. <br><br>
              God jul kjære! 
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="background-cover sixth" fluid>
      <template>
        <mdb-container class="mt-5">
          <mdb-container>
            <mdb-row class="mdb-lightbox no-margin">
              <mdb-col md="4" @click.native="showLightbox(0)">
                <figure>
                  <img src="../assets/images/synn_lunch_taverna.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(1)">
                <figure>
                  <img src="../assets/images/synn_iver_jogging_nature_laughing.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(2)">
                <figure>
                  <img src="../assets/images/synn_lunch_lokka_01.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(3)">
                <figure>
                  <img src="../assets/images/synn_middag_akerb_oslo.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(4)">
                <figure>
                  <img src="../assets/images/synn_lunch_lokka_03.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(5)">
                <figure>
                  <img src="../assets/images/synn_iver_lap_balkong_laughing.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(6)">
                <figure>
                  <img src="../assets/images/synn_vogn_iver_vinter_loren.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(7)">
                <figure>
                  <img src="../assets/images/synn_lunch_lokka_01_16-12.png" class="img-fluid" alt="">
                </figure>
              </mdb-col>
              <mdb-col md="4" @click.native="showLightbox(8)">
                <figure>
                  <img src="../assets/images/Iver_Emil_16-12.jpg" class="img-fluid" alt="">
                </figure>
              </mdb-col>
            </mdb-row>
          </mdb-container>
          <mdb-lightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          ></mdb-lightbox>
        </mdb-container>
      </template>
    </b-container>
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbLightbox } from "mdbvue";
export default {
  name: "GalleryPage",
  data() {
    return {
      imgs: [
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(117).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(98).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(131).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(123).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(118).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(128).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(132).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(115).webp',
        'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(133).webp'
      ],
      visible: false,
      index: 0
    };
  },
  methods: {
    showLightbox(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    }
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbLightbox,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  color: white;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

p {
  color: white;
}

.background-cover {
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0);
    min-height: 100vh;
    opacity: 1;
}

.background-cover.first {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/bwtgal6MJLM/1600x900")
}
.background-cover.second {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/0WTi_0Xh9rQ/1600x900")
}
.background-cover.third {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/9R1QOrTRR9s/1600x900")
}
.background-cover.fourth {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/_TuI8tZHlk4/1600x900")
}
.background-cover.fifth {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/cGW1w-qLix8/1600x900")
}
.background-cover.sixth {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/9_JUWiKULUU/1600x900")
}
.background-cover.seventh {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/_Epwsnd0f_U/1600x900")
}
.background-cover.eighth {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/R5SrmZPoO40/1600x900")
}
.background-cover.godjul {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/SBdmQcW8qag/1600x900")
}
.background-cover.slides-left {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/5PQn41LFsQk/1600x900")
}
.background-cover.slides-middle {
  height: 978px;
  /* background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/SBdmQcW8qag/1600x900") */
  background-color: black;
}
.background-cover.slides-right {
  background-image:
    linear-gradient(
      rgba(0,0,0,0.7),
      rgba(0,0,0,0.7)
    ), 
    url("https://source.unsplash.com/IPubTLY7Ji0/1600x900")
  

}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding: 0px;
}
  @media (min-width: 768px) {
    .carousel-multi-item-2 .col-md-3 {
      float: left;
      width: 25%;
      max-width: 100%; } }

    .carousel-multi-item-2 .card img {
      border-radius: 2px; }

    figure {
      cursor: pointer;
    }
</style>
